.fade-in {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
  }
  
  .fade-out {
    animation: fadeOut 0.3s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
  