@font-face {
  font-family: 'MountainSignature';
  src: url('../fonts/MountainSignature-9YaqZ.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Bavex';
  src: url('../fonts/Bavex-jEwzl.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/MetropolisPersonalUseRegular-nR5LY.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@import 'leaflet/dist/leaflet.css';
@font-face {
  font-family: 'AvantGardeMedium';
  src: url('/fonts/AvantGarde-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AvantGardeItalic';
  src: url('/fonts/avant_garde_italic.ttf') format('TrueType');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Brownhill Script Regular';
  src: url('/fonts/brownhill-script.regular.ttf') format('TrueType');
  font-weight: normal;
  font-style: normal;
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* For Safari/Chrome */
    color-adjust: exact !important;              /* For modern browsers */
  }
}

.dropdown a {
    transition: background-color 0.3s;
  }
  
  .dropdown a:hover {
    background-color: #f3f4f6;
  }
/* Applying the font to the body */
body {
  font-family:'avantGardeMedium',sans-serif;
  background-color: #d2eae5;
}