.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(5px);
    z-index: 1;
  }

  .absolute {
    top: 100%;
    z-index: 2; 
    width: 100%;
    }