.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 50px;
  min-height: 100vh;
}

.full-background {
  width: 90%;
  max-width: 1200px;
  background-color: white;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 20px;
  position: relative; 
}

.content-container {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border-radius: 10px;
  z-index: 0;
}

.phone-section {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.phone-icon img {
  width: 30px;
  height: 30px;
}

.phone-number {
  margin-right: 20px;
}

.icon {
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.facebook-icon:hover {
  color: blue;
}

.instagram-icon:hover {
  color: red;
}

.map-container {
  position: absolute; 
  top: 20%;
  left: 60%;
  width: 20%;
  height: 400px;
  border-radius: 15px;
}



