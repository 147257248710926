.button {
    padding: 0.8em 1.6em; 
    border: none;
    border-radius: 3px; 
    font-weight: bold;
    letter-spacing: 3px; 
    text-transform: uppercase;
    cursor: pointer;
    color: #0072a4;
    transition: all 1000ms;
    font-size: 14px; 
    position: relative;
    overflow: hidden;
    outline: 2px solid #0072a4;
}

button:hover {
    color: #ffffff;
    transform: scale(1);
    outline: 2px solid #0072a4;
    box-shadow: 2px 3px 10px -2px #0072a4; 
}

button::before {
    content: "";
    position: absolute;
    left: -40px; 
    top: 0;
    width: 0;
    height: 100%;
    background-color: #0072a4;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}

button:hover::before {
    width: 200%; 
}